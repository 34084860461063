import React from 'react';
import Medium from '../assets/images/medium.svg';
import telegram from '../assets/images/telegram.svg';
import twitter from '../assets/images/twitter.svg';
function Myfooter() {
  return (
    <footer className="App_footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            {/* <div className="footer_logo">
              <img src={logo} alt="Logo"/>
            </div> */}
            <div className="sociallinks">
              <a href='https://t.me/angelpoop_gang' target='_blank'><img src={telegram} alt="telegram"/></a>
              <a href='https://x.com/AngelPoop_meme' target='_blank'><img src={twitter} alt="twitter"/></a>
              <a href='https://medium.com/@AngelPoopMeme' target='_blank'><img src={Medium} alt="twitter"/></a>
            </div>
            <p>© Copyright 2024, AngelPoop <i class="fa-brands fa-telegram"></i></p>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Myfooter;