import {React,useState,useEffect} from "react";
import {MDBContainer,MDBNavbar,MDBNavbarBrand,MDBNavbarToggler,MDBIcon,MDBNavbarNav,MDBNavbarItem,MDBNavbarLink,MDBDropdown,MDBDropdownToggle,MDBDropdownMenu,MDBDropdownItem,MDBCollapse,} from 'mdb-react-ui-kit';
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/main.css";
import logo from '../assets/images/logo.png';
import telegram from '../assets/images/telegram.svg';
import twitter from '../assets/images/twitter.svg';
import Medium from '../assets/images/medium.svg';

const Myheader = () => {
  const [openBasic, setOpenBasic] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
    window.removeEventListener('scroll', handleScroll);
    };
}, []);

  return (  
    <>
    <header className={isFixed ? "App_header fixed_header" : "App_header"} role="banner">
      <MDBNavbar expand='md'>
        <MDBContainer fluid>
          <MDBNavbarBrand href='/'><img src={logo}/></MDBNavbarBrand>
          <MDBNavbarToggler
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation' onClick={() => setOpenBasic(!openBasic)}>
            <MDBIcon icon='bars' solid />
          </MDBNavbarToggler>
          <MDBCollapse navbar open={openBasic}>
            <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
              <MDBNavbarItem>
                <MDBNavbarLink active href='#'>Mini Game Coming Soon</MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink active href='#about'>About</MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href='#Pooponomics'>Pooponomics</MDBNavbarLink>
              </MDBNavbarItem>      
              <MDBNavbarItem>
                <MDBNavbarLink href='#howtobuy'>How To Buy</MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href='#team'>Team</MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </header>

  <div className="headersociallinks">
    <a href='https://t.me/angelpoop_gang' target='_blank'><img src={telegram} alt="telegram"/></a>
    <a href='https://x.com/AngelPoop_meme' target='_blank'><img src={twitter} alt="twitter"/></a>
    <a href='https://medium.com/@AngelPoopMeme' target='_blank'><img src={Medium} alt="twitter"/></a>
  </div>
  </>
  );
};

export default Myheader;